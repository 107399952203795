:root {
  --color-Uppy-Primary: #1D3440;
  --color-Uppy-Primary-hover: #000d1a;
}

.uppy-Dashboard-AddFiles {
    border: none !important;
    height: 100% !important;
    margin: 0 !important;
    background-color: var(--colors-containerBackground);
    border-radius: var(--radii-3);
}

.uppy-Dashboard-inner {
    border: 1px dashed var(--colors-lightText);
    min-height: 150px !important;
    max-height: 400px !important;
    height: auto !important;
    overflow-y: scroll !important;
}
.uppy-Dashboard-innerWrap {
    min-height: 150px !important;
    max-height: 400px !important;
    height: auto !important;
}

.uppy-Dashboard-FileCard-preview {
    height: auto !important;
}

.uppy-Dashboard-Item {
    height: auto !important;
    /*width: 150px !important;*/
}

.uppy-Dashboard-AddFiles-title {
    color: var(--colors-primaryText);
    white-space: pre-line;
}

.uppy-Dashboard-FileCard-actionsBtn{
    background-color: var(--color-Uppy-Primary);
}
.uppy-Dashboard-FileCard-actionsBtn:hover{
    background-color: var(--color-Uppy-Primary-hover);
}

@media screen and (min-width: 600px) {
    .uppy-Dashboard-AddFiles-title:first-line {
        font-size: var(--fontSizes-10) !important;
    }
    .uppy-Dashboard-browse {
        font-size: var(--fontSizes-8) !important;
    }
}

.uppy-Dashboard-browse {
    color: var(--color-Uppy-Primary) !important;
    border: none;

}

.uppy-DashboardContent-addMore{
    color: var(--color-Uppy-Primary) !important
}

.uppy-DashboardContent-back{
    color: var(--color-Uppy-Primary) !important
}
.uppy-Dashboard-browse:hover{
    border-bottom: 1px solid var(--color-Uppy-Primary) !important;

}
.uppy-DashboardTab .uppy-DashboardTab-btn:not([aria-controls]) svg {
    visibility: hidden !important;
}
.uppy-DashboardTab .uppy-DashboardTab-btn:not([aria-controls])::before {
    content: url("upload.svg");
}

.uppy-Dashboard-FileCard button[type="button"] {
    visibility: hidden !important
}

.uppy-Dashboard-FileCard-info{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
}

.uppy-Dashboard-FileCard-fieldset {
    display: grid !important;
    grid-template-columns: 30% 70% !important;
    width: 100% !important;
}


.uppy-Dashboard-FileCard-fieldset label{
    width: auto !important;
}

.uppy-Dashboard-FileCard-fieldset select{
    width: 100% !important;
}

.uppy-Dashboard-FileCard-fieldset input{
    width: 100% !important;
}
